import "./App.css";
import ProtectedRoute from "./Routes/ProtectedRoute";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import Login from "./screens/Login";
import AfterLogin from "./screens/AfterLogin";
import Gallery from "./screens/Gallery";
import MainMenu from "./screens/MainMenu";
import { msalInstance } from "./auth/msalInstance";
import CustomBackground from "./screens/CustomBackground";
import CropPage from "./screens/CropPage";
import VideoTrimmer from "./screens/VideoEditor/VideoTrimmer";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./themes/theme.js";

function App() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <MsalProvider instance={msalInstance}>
        <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/afterlogin" element={<AfterLogin />} />
              <Route
                path="/"
                element={<LandingRedirect isAuthenticated={isAuthenticated} />}
              />
              <Route
                path="/main"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <MainMenu />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/gallery"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <Gallery />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/add-background"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <CustomBackground />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/crop"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <CropPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/edit-video/:videoId"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <VideoTrimmer />
                  </ProtectedRoute>
                }
              />

              {/* Here you can add other routes that require authentication */}
            </Routes>
            <div style={{ flexGrow: 1 }} />

            <div style={{ background: '#7A9A01', height: '20px', width: '100%' }} />
          </div>
        </MsalProvider>
      </Router>
    </ThemeProvider>
  );
}

const LandingRedirect = ({ isAuthenticated }) => {
  return isAuthenticated ? (
    <Navigate to="/main" replace />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default App;
