import { BrowserCacheLocation } from "@azure/msal-browser";


export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    clientSecret: process.env.REACT_APP_CLIENTSECRET // Achtung: Verwendung auf Clientseite nicht empfohlen!
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage // "sessionStorage"
  },
};


