import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7A9A01', // Ihre primäre Farbe
    },
    secondary: {
      main: '#1976d2', // Typisches MUI Blau
    },
    // ...weitere Farben oder Anpassungen
  },
  typography: {
    fontFamily: [
      'Calibri', // Fügen Sie Calibri als Haupt-Schriftart hinzu
      'sans-serif', // Fallback-Schriftarten
    ].join(','),
    fontSize: 16, // Setzen Sie die Basis-Schriftgröße auf 16px
    // Setzen Sie alle Texte auf schwarz und fett
    allVariants: {
      color: 'black', // Alle Textvarianten sollen schwarz sein
      fontWeight: 'bold', // Alle Textvarianten sollen fett gedruckt sein
    },
  },
  // ...weitere Theme-Konfigurationen
});

export default theme;
