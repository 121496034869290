import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useMsal } from '@azure/msal-react';

function MainMenu() {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect().then(() => {
      sessionStorage.clear();
     }).catch(e => {
      console.error(e);
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '90vh' }}>
      <div style={{ padding: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <img src="/katho_logo.png" alt="Katho Logo" style={{ height: 50 }} />
        <Button 
          variant="contained" 
          color="error"
          onClick={handleLogout}
          style={{ margin: 8 }}
        >
          Abmelden
        </Button>
      </div>
      <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h3" component="h1" gutterBottom>
          One Button Recording Studio - Hauptmenü
        </Typography>
        <Button 
  variant="contained" 
  color="primary" 
  onClick={() => navigate("/add-background")}
  sx={{
    width: '80%',
    maxWidth: 360,
    m: 1,
    px: 4, // padding left and right
    py: 2, // padding top and bottom
    fontSize: '1.25rem', // larger font size
    minHeight: '48px', // minimum height
  }}
>
  Benutzerdefinierten Background hinzufügen
</Button>
<Button 
  variant="contained" 
  color="primary" 
  onClick={() => navigate("/gallery")}
  sx={{
    width: '80%',
    maxWidth: 360,
    m: 1,
    px: 4, // padding left and right
    py: 2, // padding top and bottom
    fontSize: '1.25rem', // larger font size
    minHeight: '48px', // minimum height
  }}
>
  Videos ansehen und bearbeiten
</Button>

      </div>
    </div>
  );
}

export default MainMenu;
