import { InteractionRequiredAuthError } from "@azure/msal-browser";

// Async Funktion, um den Token zu erwerben
const getToken = async (msalInstance, scopes) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    try {
      // Versuche, einen Token still zu erwerben
      const response = await msalInstance.acquireTokenSilent({
        scopes: scopes,
        account: accounts[0],
      });
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Bei Bedarf interaktive Authentifizierung
        try {
          const response = await msalInstance.acquireTokenRedirect({
            scopes: scopes,
            account: accounts[0],
          });
          return response.accessToken;
        } catch (interactiveError) {
          console.error("Interaktive Authentifizierung fehlgeschlagen:", interactiveError);
        }
      } else {
        console.error("Token-Akquise fehlgeschlagen:", error);
      }
    }
  } else {
    console.log("Keine Konten gefunden.");
  }
  return null;
};

export default getToken;
