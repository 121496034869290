import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Button, Box, Typography } from '@mui/material'; // Importiere zusätzliche MUI-Komponenten

function Login() {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/main'); // Weiterleitung zu /main, wenn der Benutzer authentifiziert ist
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = () => {
    // Gibt den `prompt: 'login'`-Parameter an, um jedes Mal die Anmeldeseite zu erzwingen
    const loginRequest = {
      scopes: ["user.read"], // Setzen Sie hier die Scopes, die Ihre Anwendung benötigt
      prompt: "login" // Erzwingt das manuelle Eingeben der Anmeldedaten
    };

    // Führt die modifizierte Login-Anforderung aus
    instance.loginRedirect(loginRequest).catch(e => {
      console.error("Error during login process:", e);
    });
};

  // Verwende Box-Komponenten für Layout und Zentrierung
  return (
    <div>
    <div style={{ padding: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <img src="/katho_logo.png" alt="Katho Logo" style={{ height: 50 }} />
      </div>
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center"
    >
      <Typography variant="h4" component="h2" gutterBottom>
        Login zum OBRS der katho NRW
      </Typography>
      <Button 
        variant="contained" 
        onClick={handleLogin} 
        sx={{ mt: 2 }} // Margin Top für Abstand
      >
        Mit Microsoft anmelden
      </Button>
    </Box>
    </div>
  );
}

export default Login;
