import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Container,
  CircularProgress,
  Backdrop,
  Typography,
} from "@mui/material";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";

// let ffmpeg; // Store the ffmpeg instance

function VideoTrimmer() {
  const location = useLocation();
  const navigate = useNavigate();
  const { videoId } = useParams();
  const { token, username } = location.state; // Empfangene Daten aus dem State
  const [videoDuration, setVideoDuration] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [videoTrimmedUrl, setVideoTrimmedUrl] = useState("");
  const videoRef = useRef();
  const videoUrl = `${process.env.REACT_APP_API_URL}/videos/${username}/recordings/${videoId}`;
  const [isTrimming, setIsTrimming] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const ffmpeg = createFFmpeg({ 
    log: true, 
    corePath: (process.env.NODE_ENV === 'development')
      ? "/ffmpeg-core.js" 
      : process.env.PUBLIC_URL + "/ffmpeg-core.js"
  });


  // useEffect(() => {
  //   console.log("angekommenes", username, token);
  //   const loadScript = (src) => {
  //     return new Promise((resolve, reject) => {
  //       const script = document.createElement("script");
  //       script.async = true;
  //       script.defer = true;
  //       script.src = src;
  //       script.onload = () => resolve(script);
  //       script.onerror = reject;
  //       document.head.appendChild(script);
  //     });
  //   };

  //   loadScript(
  //     "https://cdn.jsdelivr.net/npm/@ffmpeg/ffmpeg@0.11.2/dist/ffmpeg.min.js"
  //   )
  //     .then(() => {
  //       if (window.FFmpeg) {
  //         ffmpeg = window.FFmpeg.createFFmpeg({ log: true });
  //         ffmpeg.load().then(() => setIsScriptLoaded(true));
  //       }
  //     })
  //     .catch((e) => console.error("Failed to load the ffmpeg script", e));
  // }, []);

  useEffect(() => {
    const handleMetadata = () => {
      if (videoRef.current) {
        setVideoDuration(videoRef.current.duration);
        setEndTime(videoRef.current.duration);
      }
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", handleMetadata);
      return () => {
        if (videoElement) {
          // Sicherstellen, dass das Element existiert, bevor der Listener entfernt wird
          videoElement.removeEventListener("loadedmetadata", handleMetadata);
        }
      };
    }
  }, [videoUrl]); // Abhängigkeit videoUrl hinzugefügt, um sicherzustellen, dass der Effekt ausgeführt wird, wenn sich videoUrl ändert

  const handleVideoTimeUpdate = (e) => {
    const current = e.target.currentTime;
    setCurrentTime(current);
    if (Math.floor(current) >= Math.floor(endTime)) {
      e.target.pause();
    }
  };

  const handleTrim = async () => {
    if (!ffmpeg.isLoaded()) {
      await ffmpeg.load();
    }
  
    setIsTrimming(true);
    setLoadingMessage("Video wird zugeschnitten...");
  
    const response = await fetch(videoUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const videoBlob = await response.blob();
  
    // Erstellt einen temporären Dateinamen basierend auf videoId
    const filename = `${videoId.split(".")[0]}.mp4`;
  
    ffmpeg.FS("writeFile", "input.mp4", await fetchFile(videoBlob));
    await ffmpeg.run(
      "-i",
      "input.mp4",
      "-ss",
      convertToHHMMSS(startTime),
      "-to",
      convertToHHMMSS(endTime),
      "-c",
      "copy",
      filename
    );
    const data = ffmpeg.FS("readFile", filename);
    const trimmedVideoBlob = new Blob([data.buffer], {type: "video/mp4"});
    const trimmedVideoUrl = URL.createObjectURL(trimmedVideoBlob);
    setVideoTrimmedUrl(trimmedVideoUrl);
    setLoadingMessage("Video wird hochgeladen...");
  
    // Hochladen der getrimmten Videodatei
    await uploadTrimmedVideo(trimmedVideoBlob, filename);
    setIsTrimming(false);
    navigate(-1); // Navigates back after the process is complete

  };

  const uploadTrimmedVideo = async (blob, filename) => {
    const formData = new FormData();
    formData.append("video", blob, filename);

    // Erstellen der URL mit Query-Parametern
    const url = new URL(
      `${process.env.REACT_APP_API_URL}/add-recordings/upload-trimmed-video`
    );
    url.searchParams.append("username", username); // Fügen Sie den Benutzernamen als Query-Parameter hinzu

    const response = await fetch(url.toString(), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`, // Sicherstellen, dass token vorhanden und gültig ist
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(
        `Failed to upload the trimmed video: ${response.statusText}`
      );
    }

    const result = await response.json();
    console.log("Upload response:", result);
    return result; // Rückgabe des Ergebnisses, nützlich je nach Anwendungsfluss
  };

  const convertToHHMMSS = (val) => {
    const secNum = parseInt(val, 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum % 3600) / 60);
    const seconds = secNum % 60;
    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":");
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div style={{ position: "absolute", top: 0, left: 0, margin: "10px" }}>
        <Button startIcon={<ArrowBackIcon />} onClick={goBack}>
          Zurück
        </Button>
      </div>

      <Container maxWidth="lg" style={{ width: "80%", margin: "auto" }}>
        <div
          className="video-wrapper"
          style={{ maxWidth: "100%", marginTop: "40px" }}
        >
          <video
            ref={videoRef}
            controls
            style={{ width: "100%" }}
            onTimeUpdate={handleVideoTimeUpdate}
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
          <br />
          {videoDuration > 0 && (
            <Nouislider
              start={[startTime, endTime]}
              connect={true}
              range={{ min: 0, max: videoDuration }}
              step={0.1}
              onUpdate={(values, handle) => {
                const value = parseFloat(values[handle]);
                if (handle === 0) {
                  setStartTime(value);
                  videoRef.current.currentTime = value;
                } else if (handle === 1) {
                  setEndTime(value);
                }
              }}
              tooltips={true}
            />
          )}
          <Typography variant="body1" gutterBottom>
            Aktuelle Zeit im Video: {convertToHHMMSS(currentTime)} | Start des
            Zuschnitt: {convertToHHMMSS(startTime)} | Ende des Zuschnitt:{" "}
            {convertToHHMMSS(endTime)}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleTrim}
            style={{ marginTop: "10px" }}
          >
            Video zuschneiden
          </Button>
          {videoTrimmedUrl && (
            <video controls style={{ width: "100%", marginTop: "10px" }}>
              <source src={videoTrimmedUrl} type="video/mp4" />
            </video>
          )}
        </div>
      </Container>

      <Backdrop open={isTrimming} style={{ color: "#fff", zIndex: 10 }}>
        <CircularProgress color="inherit" />
        <Typography variant="h5" style={{ marginLeft: "20px", color: "#fff" }}>
          {loadingMessage}
        </Typography>
      </Backdrop>
    </div>
  );
}

export default VideoTrimmer;
