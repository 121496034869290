export function findUsernameInSessionStorage() {
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      try {
        const value = JSON.parse(sessionStorage.getItem(key));
        if (value && value.username) {
          return value.username;
        }
      } catch (error) {
        // Ignoriert den Fehler
      }
    }
    return null;
  }