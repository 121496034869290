import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

function AfterLogin() {
  const { instance } = useMsal();
  const navigate = useNavigate();


  useEffect(() => {
    const handleTokenAcquisition = async () => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        const account = accounts[0]; // Wähle das erste verfügbare Konto
        instance.setActiveAccount(account); // Setze das aktive Konto
        
        const request = {
          scopes: ["User.Read"],
          account: account, // Füge das Konto zur Anfrage hinzu
        };
  
        try {
          const tokenResponse = await instance.acquireTokenSilent(request);
          console.log("Stille Token-Akquise erfolgreich:", tokenResponse);
          // Navigiere zur Hauptseite
          navigate('/main');
        } catch (error) {
          console.error("Fehler bei der stillen Token-Akquise:", error);
          // Eventuell einen interaktiven Auth Flow initiieren
        }
      } else {
        const inProgress = instance.getInteractionStatus() === "interaction_required";
        if (!inProgress) {
            console.log("Keine Konten im Cache gefunden, starte interaktive Authentifizierung...");
            instance.loginRedirect({ scopes: ["User.Read"] }).catch(loginError => {
                console.error("Fehler bei der interaktiven Authentifizierung:", loginError);
            });
        }
    }
};

handleTokenAcquisition();
}, [instance, navigate]);
  return <div>Authentifizierung wird verarbeitet...</div>;
}

export default AfterLogin;