import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CropPage = () => {
    const [image, setImage] = useState(null);
    const [open, setOpen] = useState(false);
    const [backgroundName, setBackgroundName] = useState("");
    const cropperRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigiert einen Schritt zurück im Browser-Verlauf
      };

    useEffect(() => {
        if (location.state?.file) {
            const reader = new FileReader();
            reader.addEventListener("load", () => setImage(reader.result));
            reader.readAsDataURL(location.state.file);
        } else {
            navigate("/custom-backgrounds");
        }
    }, [location, navigate]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        console.log("backgroundName", backgroundName);
        setOpen(false);
    
        if (!cropperRef.current) {
            alert("Bitte laden Sie zuerst ein Bild hoch und ziehen Sie einen Ausschnitt!");
            return;
        }
    
        if (!backgroundName) {
            alert("Bitte geben Sie einen Namen für das hochzuladene Bild ein!");
            return;
        }
    
        const canvas = cropperRef.current.getCanvas();
        if (!canvas) {
            alert("Bitte ziehen Sie einen Ausschnitt, bevor Sie hochladen!");
            return;
        }
    
        canvas.toBlob(async (blob) => {
            const formData = new FormData();
            formData.append("background", blob);
            formData.append("backgroundName", backgroundName);
    
            const apiURL = `${process.env.REACT_APP_API_URL}/custom-backgrounds/upload-background`;
            try {
                const response = await axios.post(`${apiURL}?username=${encodeURIComponent(location.state.username)}`, formData, {
                    headers: {
                        'Authorization': `Bearer ${location.state.token}`
                    },
                });
    
                console.log("Server Response: ", response.data);
                alert("Bild erfolgreich hochgeladen!");
                navigate("/add-background");
            } catch (error) {
                console.error("Upload fehlgeschlagen: ", error);
                if (error.response && error.response.status === 409) {
                    alert("Ein Bild mit diesem Namen existiert bereits. Bitte wählen Sie einen anderen Namen.");
                } else {
                    alert("Fehler beim Hochladen des Bildes: " + (error.response ? error.response.data.error : "Unbekannter Fehler"));
                }
            }
        }, 'image/png');
    };
    

    return (
        
        <div style={{ height: '100vh', overflow: 'auto' }}>
                 <div style={{ position: 'absolute', top: 0, left: 0, margin: '10px' }}>
  <Button // Material-UI Zurück-Button mit Icon
    startIcon={<ArrowBackIcon />}
    onClick={goBack}
  >
    Zurück
  </Button>
</div>
            <h1>Bild zuschneiden</h1>
            {image && (
                <div style={{ width: '100vw', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Cropper
                        src={image}
                        className={"cropper"}
                        stencilProps={{ aspectRatio: 16 / 9 }}
                        ref={cropperRef}
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                </div>
            )}
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                Bild hochladen
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>{"Benennen Sie Ihren benutzerdefinierten Hintergrund"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Geben Sie einen Namen für Ihren benutzerdefinierten Hintergrund ein.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Hintergrundname"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={backgroundName}
                        onChange={e => setBackgroundName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button onClick={handleSave}>Speichern</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
    
};

export default CropPage;
